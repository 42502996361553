import styled from '../../../../../_snowpack/pkg/styled-components.js';
export const Menu = styled.ul.withConfig({
  displayName: "styles__Menu",
  componentId: "sc-1bf2z7-0"
})(["background:#fefefe;border:1px solid ", ";border-radius:0 0 4px 4px;margin-top:10px;max-height:40vh;outline:0;overflow-x:hidden;overflow-y:auto;padding:0;position:absolute;transition:opacity 0.1s ease;width:100%;z-index:2;left:-1px;width:calc(100% + 2px);&.open{border:1px solid #dfdfdf;box-shadow:0 2px 4px rgba(0,0,0,0.05);border-top:1px solid ", ";}"], ({
  theme
}) => theme.colors.white, ({
  theme
}) => theme.colors.frost);
export const MenuItem = styled.li.withConfig({
  displayName: "styles__MenuItem",
  componentId: "sc-1bf2z7-1"
})(["border-style:none;cursor:pointer;display:block;font-size:14px;font-weight:500;height:auto;padding:15px 10px 15px 20px;position:relative;text-align:left;text-transform:none;white-space:normal;word-wrap:unset;color:", ";&.active{background-color:rgba(0,0,0,0.03);}&[aria-selected='true']{background-color:rgba(0,0,0,0.03);}&:hover{background-color:rgba(0,0,0,0.03);}&.selected{font-weight:700;display:none;}"], ({
  theme
}) => theme.colors.frostbite);
export const SearchWrapper = styled.div.withConfig({
  displayName: "styles__SearchWrapper",
  componentId: "sc-1bf2z7-2"
})(["align-items:center;display:flex;flex-direction:row;justify-content:space-between;width:100%;margin-right:15px;&.open input{padding-left:10px;}input{align-self:start;background-color:transparent;border:none;color:#4c4f5a;font-family:'Roboto';font-size:14px;font-weight:600;width:inherit;cursor:pointer;padding-top:20px;height:40px;max-width:170px;transition:padding-left 0.1s ease-in-out;&:focus{outline:none;}}"]);
export const Label = styled.label.withConfig({
  displayName: "styles__Label",
  componentId: "sc-1bf2z7-3"
})(["color:#828a90;font-size:12px;font-weight:600;opacity:0.5;float:left;position:absolute;top:13px;left:10px;transition:left 0.1s ease-in-out;&.open{left:20px;}"]);
export const Wrapper = styled.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-1bf2z7-4"
})(["display:block;position:relative;padding-top:0.5rem;width:230px;margin-left:10px;padding-left:10px;padding-bottom:10px;border-width:1px;border-style:solid;border-color:transparent;border-radius:4px 4px 0 0;top:10px;&:hover{background:rgba(0,0,0,0.02);}&.open{border-color:#dfdfdf;}"]);
export const ChevronButton = styled.button.withConfig({
  displayName: "styles__ChevronButton",
  componentId: "sc-1bf2z7-5"
})(["background-color:transparent;border:none;cursor:pointer;height:40px;width:40px;margin-right:25px;position:relative;display:flex;align-items:center;justify-content:center;svg{width:12px;height:12px;pointer-events:none;}&.open{transform:rotate(180deg);}&:focus{outline:none;}"]);