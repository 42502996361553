import { useCallback, useEffect } from '../../_snowpack/pkg/react.js';
/**
 * Hook that allow to listen to click outside for a given element
 *
 * @param {Element} ref - The reference element to add the event listener
 * @callback handler - The function to be called when the event happens
 * @param {Object} options - Options the includes the listen property
 * @param {boolean} options.listen - Parameter to listen for the event, defaults to true but can be conditional
 */

function useOnClickOutside(ref, handler, {
  listen = true
} = {}) {
  const handlerCallback = useCallback(handler, [handler]);
  useEffect(() => {
    const listener = event => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }

      handlerCallback(event);
    }; // Don't add the event listener if there is not need


    if (!listen) {
      return;
    }

    document.addEventListener('click', listener, false);
    return () => {
      document.removeEventListener('click', listener, false);
    };
  }, // eslint-disable-next-line react-hooks/exhaustive-deps
  [listen] // Add ref and handler to effect dependencies
  // It's worth noting that because passed in handler is a new ...
  // ... function on every render that will cause this effect ...
  // ... callback/cleanup to run every render. It's not a big deal ...
  // ... but to optimize you can wrap handler in useCallback before ...
  // ... passing it into this hook.
  );
}

export default useOnClickOutside;