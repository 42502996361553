import gql from '../../_snowpack/pkg/graphql-tag.js';
export const GET_TREE_DATA = gql`
  query GET_TREE_DATA($id: ID!, $language: String!) {
    tree {
      getNode(itemId: $id) {
        itemId
        item(language: $language) {
          id
          name
          language
        }
        children {
          itemId
          position
          parentId
          item(language: $language) {
            type
            id
            name
            language
          }
        }
      }
    }
  }
`;