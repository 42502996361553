export { A as ApolloProvider, d as useApolloClient, c as useLazyQuery, a as useMutation, u as useQuery } from '../common/react-hooks.esm-80399c0b.js';
import '../common/index-ec48241a.js';
import '../common/_commonjsHelpers-37fa8da4.js';
import '../common/bundle.esm-7ed9fa05.js';
import '../common/process-2545f00a.js';
import '../common/visitor-781df79b.js';
import '../common/ast-9ee6b6e8.js';
import '../common/bundle.esm-79491b4a.js';
import '../common/bundle.esm-12c39e8e.js';
import '../common/index-2f784aea.js';
import '../common/equality.esm-5339f780.js';
