import styled from '../../../_snowpack/pkg/styled-components.js';
export const Wrapper = styled.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-167g1mg-0"
})(["display:flex;place-content:space-evenly;place-items:center;height:100vh;width:100vw;background-color:", ";padding:100px;img{max-width:40%;max-height:80vh;}"], ({
  theme
}) => theme.colors.frost);
export const Saying = styled.div.withConfig({
  displayName: "styles__Saying",
  componentId: "sc-167g1mg-1"
})(["display:flex;flex-direction:column;color:", ";> h2{font-size:2.25rem;}> p{line-height:1.4;}button{width:max-content;margin-top:1rem;}"], ({
  theme
}) => theme.colors.frostbite);