import { useCallback, useEffect, useState } from '../../_snowpack/pkg/react.js';
/**
 * Hook that allow to listen for a key pressed
 *
 * @param {string} targetKey - The key that the event show listen for
 * @callback handler - The function to be called when the event happens
 */

function useKeyPress(targetKey, handler) {
  // State for keeping track of whether key is pressed
  const [keyPressed, setKeyPressed] = useState(false); // If pressed key is our target key then set to true

  const downHandler = useCallback(({
    key
  }) => {
    if (key === targetKey) {
      setKeyPressed(true);
    }
  }, [targetKey]); // If released key is our target key then set to false

  const upHandler = useCallback(({
    key
  }) => {
    if (key === targetKey) {
      setKeyPressed(false);
    }
  }, [targetKey]); // Add event listeners

  useEffect(() => {
    window.addEventListener('keydown', downHandler, true);
    window.addEventListener('keyup', upHandler, true); // Remove event listeners on cleanup

    return () => {
      window.removeEventListener('keydown', downHandler, true);
      window.removeEventListener('keyup', upHandler, true);
    };
  }, [downHandler, upHandler]); // Empty array ensures that effect is only run on mount and unmount

  useEffect(() => {
    // call handler in case key is pressed
    if (keyPressed && handler) {
      handler();
    }
  }, [handler, keyPressed]);
  return keyPressed;
}

export default useKeyPress;